.bgColor{
    background-color: var(--create-button-bg) !important;
    color: var(--button-color) !important;
}
.bgColor:disabled{
    opacity: .7;
}
.bgColorOutlined{
    border-color: var(--button-color-outlined) !important;
    color: var(--button-color-outlined) !important;
}
.title{
    font-size: 1.5rem !important;
}
.text{
 cursor: pointer;
 margin: 10px 0;
 border: 1px solid black;
 padding: 5px;
 text-decoration: none;
 list-style: none;
}
.text:hover{
    color: #3b3b3b;
}
.subTitle{
    font-size: 1rem;
    font-weight: 400;
    color: var(--subtitle-modal-text-color);
}
.container{
    margin-top: 2px;
}
.gridItem{
    padding-top: 0;
}
.input{
    display: flex !important;
    flex-direction: column !important;
    align-items: start !important;
    justify-content: end !important;
}
.inputSelect{
    width: 100% !important;
}
.label{
    font-size: 14px;
}
.span{
    font-size: 14px;
    font-weight: 400;
}
.button{
    margin-top: 10px !important;
    width: 150px;
    margin-bottom: 10px !important;
}
.menuIcon {
    visibility: visible !important;
}
.buttonContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 5px;
}
.textIcon{
    font-weight: 400 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
}
.bgColorDelete{
    background-color: var(--delete-button-bg) !important;
    color: var(--button-color) !important;
}
.buttonContainerDelete{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
}
.boxDelete{
    padding: 20px 0;
}
.containerDelete{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 20px;
}
.containerEdit{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 20px;
}
.buttonContainerEdit{
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    flex-wrap: wrap;
    gap: 5px;
}
.tableContainer {
    width: 100%;
    margin-top: 20px;
  }
  
  .tableContainer h2,
  .tableContainer h3 {
    margin-bottom: 5px;
  }
  
  .dataTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .dataTable th,
  .dataTable td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
  }
  
  .dataTable th {
    background-color: #f2f2f2;
  }
  
  .dataTable tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .areaRow:hover {
    background-color: #e3e3e3;
  }
  
  .empresaItem {
    margin-bottom: 5px;
  }
  /* styles.module.css */
.tableContainer {
    width: 100%;
    margin-top: 20px;
  }
  
  .vpContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid #dddddd;
    padding: 10px;
    margin-bottom: 15px;
  }
  
  .vpName {
    text-align: center;
    margin-bottom: 5px;
  }
  
  .areasContainer {
    display: flex;
  }
  
  .gerenciaContainer {
    border: 1px solid #dddddd;
    padding: 10px;
    margin-right: 10px;
  }
  
  .gerenciaName {
    margin-bottom: 5px;
    text-align:center;
    font-weight: 400;
  }
  
  .dataTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .dataTable th,
  .dataTable td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
  }
  
  .dataTable th {
    background-color: #f2f2f2;
  }
  
  .dataTable tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .areaRow:hover {
    background-color: #e3e3e3;
  }
  
  .empresaItem {
    margin-bottom: 5px;
    cursor: pointer;
  }
  
@media only screen and (max-width: 600px) { /* Estilos para dispositivos pequeños aquí */
    .title{
        width: 100%;
        text-align: center;
    }   
    .subTitle{
        width: 100%;
        text-justify:distribute;
    }
    .button{
        width: 100%;
    }
    .bgColor{
        width: 100%;
    }
    .bgColorOutlined{
        width: 100%;
    }
    .bgColorDelete{
        width: 100%;
    }
    .bgColorOutlined{
        width: 100%;
    }
}