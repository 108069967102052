.h-full{height: 100vh;}
.w-full{width: 100vw;}
.h-100{height: 100%;}
.d-flex{display: flex;}
.align-items-center{align-items: center;}
.justify-content-center{justify-content: center;}
.text-center{text-align: center;}
.font-200{font-weight: 200;}
.font-400{font-weight: 400;}
.font-500{font-weight: 500;}
.font-600{font-weight: 600;}
.m-4{margin:4px}
.m-6{margin:6px}
.m-8{margin:8px}
.m-10{margin:10px}
.mb-0{margin-bottom: 0;}
.pb-0{padding-bottom: 0;}


/* LOGIN */
.login_box{
    width: 600px;
}
.bg-gradient{background: radial-gradient(circle at center, #ffffff , #c4c4c4);}
.error-toast{
    color:white;
    background-color: rgb(241, 90, 90);
    padding: 5px 20px;
    border-radius: 4px;
    border: 1px solid red;
    width: 100%;
}

@media only screen and (max-width: 600px) { /* Estilos para dispositivos pequeños aquí */
    .login_box{
        width: 100%;
        min-width: 350px;
    }   
}
@media only screen and (min-width: 601px) and (max-width: 900px) { /* Estilos para tablets y dispositivos pequeños aquí */

}
@media only screen and (min-width: 901px) and (max-width: 1200px) { /* Estilos para dispositivos medianos aquí */
}

.App {
    display: flex;
    font-family: 'Poppins';
    background-color: var(--bg-color);
}
.App2 {
    display: flex;
    font-family: 'Poppins';
    background-color: #f7f7f7;
}
.menu-floating{
    position: absolute;
    top: 10px;
    left: 10px;
}