:root {
  --background-sidebar: #212227;
  --color-sidebar-items: #f0f0f5;
  --color-hover-sidebar-items: #5d5d5d;
  --create-button-bg: #174300;
  /* --create-button-bg: #afbd22; */
  --create-disabled-button-bg: #5d5d5d;
  --filter-button-bg: #333333;
  --bg-button-slider: #333333;
  --delete-button-bg: #bd1717;
  --delete-button-bg-hover: #ae1414;
  --button-color: #ffffff;
  --button-color-outlined: #333333;
  --button-border-color-outlined: #bfbfbf;
  --button-color-outlined: #363636;
  --subtitle-modal-text-color: #201c1c;

  --inst-orange: #f19d38;
  --inst-orange-dark: #e99734;
  --inst-green: #afbd22;
  --inst-green-dark: #9ca91c;
  --inst-li-blue: #0086ae;
  --inst-blue: #004a91;
  --inst-black: #fff;
  --textfield-bg: #212227;
  --inst-card-bg: #2d3037;
  --inst-icons: #989da8;
  --transparent: transparent !important;
  --border-green: #afbd22;
  --border-orange: #f19d38;
  --border-blue: #0086ae;
  /* --inst-black: #231f20; */
  --inst-black-80: #58595b;
  --inst-black-60: #808285;
  --inst-black-40: #a7a9ac;
  --inst-black-20: #d1d3d4;
  --inst-black-10: #f9f9f9;
  --inst-black-5: #efefef;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(circle at center, #ffffff, #c4c4c4);
  /* background: #f6f6f6; */
  /* color: #212227; */
  /* background-color: rgba(33, 34, 39, 0.2); */
}
.rbc-btn-group button {
  color: #000 !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  width: 100%;
}
h1 {
  font-weight: 500;
}
.iconBg {
  color: "#FFF" !important;
}
