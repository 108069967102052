.bgColor{
    background-color: var(--create-button-bg) !important;
    color: var(--button-color) !important;
}
.bgColor:disabled{
    opacity: .7;
}
.bgColorOutlined{
    border-color: var(--button-color-outlined) !important;
    color: var(--button-color-outlined) !important;
}
.title{
    font-size: 1.5rem !important;
}
.subTitle{
    font-size: 1rem;
    font-weight: 400;
    color: var(--subtitle-modal-text-color);
}
.container{
    margin-top: 2px;
}
.gridItem{
    padding-top: 0;
}
.input{
    display: flex !important;
    flex-direction: column !important;
    align-items: start !important;
    justify-content: end !important;
}
.inputSelect{
    width: 100% !important;
}
.label{
    font-size: 14px;
}
.span{
    font-size: 14px;
    font-weight: 400;
}
.button{
    margin-top: 10px !important;
    width: 150px;
    margin-bottom: 10px !important;
}
.menuIcon {
    visibility: visible !important;
}
.buttonContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 5px;
}
@media only screen and (max-width: 600px) { /* Estilos para dispositivos pequeños aquí */
    .title{
        width: 100%;
        text-align: center;
    }   
    .subTitle{
        width: 100%;
        text-justify:distribute;
    }
    .button{
        width: 100%;
    }
    .bgColor{
        width: 100%;
    }
    .bgColorOutlined{
        width: 100%;
    }
}