.gridContainer {
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.gridTextContainer {
  text-align: start;
}
.optionsContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
}
.bgColorGreen {
  background-color: var(--inst-green-dark) !important;
}
.label {
  font-weight: 600;
  font-size: 16px;
}
.labelDesc {
  font-weight: 400;
  font-size: 14px;
}
.textBox {
  display: flex;
  flex-direction: column;
}
.textBoxContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button {
  background-color: var(--inst-li-blue) !important;
  color: #fff !important;
}
.button:disabled {
  background-color: var(--create-disabled-button-bg) !important;
  color: #fff !important;
}
.navItem {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  border: 1px solid #b7b7b7;
  border-radius: 4px;
  padding: 10px;
  transition: 0.2s ease-in;
}
.navItem:hover {
  background-color: #b7b7b7;
}
.link {
  text-decoration: none;
  color: #333;
}
@media only screen and (max-width: 600px) {
  .gridTextContainer {
    text-align: center;
    margin-bottom: 10px !important;
  }
}
