.bgColor{
    background-color: var(--create-button-bg) !important;
    color: var(--button-color) !important;
}
.bgColorOutlined{
    border-color: var(--button-color-outlined) !important;
    color: var(--button-color-outlined) !important;
}
.bgColor:disabled{
    opacity: .7;
}
@media only screen and (max-width: 600px) { /* Estilos para dispositivos pequeños aquí */
    .title{
        width: 100%;
        text-align: center;
    }   
    .subTitle{
        width: 100%;
        text-justify:distribute;
    }
    .button{
        width: 100%;
    }
    .bgColor{
        width: 100%;
    }
    .bgColorOutlined{
        width: 100%;
    }
    .bgColorFilter{
        margin: 10px 0;
        width: 100%;
    }
}
.sidenav {
    width: 250px;
    transition: width 0.3s ease-in-out;
    height: 100vh !important;
    background-color: var(--background-sidebar);
    padding-top: 28px;
    
}
.sidenavClosed {
    composes: sidenav;
    transition: width 0.3s ease-in-out;
    width: 60px
}
.sideitem {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    color: var(--color-sidebar-items);
    text-decoration: none;
    overflow: hidden;
}
.linkText {
    padding-left: 16px;
}

.sideitem:hover {
    background-color: var(--color-hover-sidebar-items);

}
.menuBtn {
    align-self: center;
    align-self: flex-start;  
    justify-self: flex-end;
    color: var(--color-sidebar-items);
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left: 20px;
}

@media only screen and (max-width: 1200px) { /* Estilos para dispositivos pequeños aquí */
    .sidenav{
        display: none;
    }   
}