.hero {
  /* background-color: var(--inst-black); */
  background-color: var(--inst-green-dark-head-Dash) !important;
  color: white !important;
  display: flex;
  align-items: center;
  border-radius: 0px 0px 5px 5px !important;
  height: 190px;
  margin-bottom: 50px;
}
.linkText {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: var(--inst-text-item-color) !important;
}

.subTextLink  {
  color: var(--inst-text-item-color) !important;
  margin-left: 0;
  font-size: 12px;
  border: none;
  margin-bottom: 15px !important;
}

.heroGrid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.sliderContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0px 20px;
  /* max-height: 300px; añadido 19-11-2024*/ 
  /* overflow-y: auto; añadido 19-11-2024*/ 
}
.hero_button {
  color: white !important;
  border: none !important;
  border-radius: 0;
  background-color: #1a1414 !important;
}
.icon {
  /* background-color: var(--inst-icon-background); */
  color: var(--inst-icon-color);
  /* border-radius: 50%; */
  padding-top: 2px;
  height: 30px;
  width: 30px;
  font-size: 1.3rem !important;
}
.linkDecoration {
  text-decoration: none !important;
}
.link {
  color: white !important;
  border: none !important;
  text-decoration: none !important;
  display: flex !important;
  align-items: center !important;
}
.hero_button:hover {
  color: white !important;
  border-color: #8d8d8d !important;
  border-bottom: none !important;
  border-radius: 5px !important;
}
.icon {
  text-align: center;
}
.container {
  padding-right: 10px !important;
  padding-bottom: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
.card {
  margin: 10px 0;
  padding: 10px;
  background-color: var(--inst-li-Home-Card) !important;
  color: white !important;
}
/*  */

.bgColor {
  background-color: var(--create-button-bg) !important;
  color: var(--button-color) !important;
}
.bgSliderColorButton {
  background-color: var(--bg-button-slider) !important;
  color: var(--button-color) !important;
  margin-top: 5px;
}
.bgColorDelete {
  background-color: var(--delete-button-bg) !important;
  color: var(--button-color) !important;
}
.bgColorOutlined {
  border-color: var(--button-color-outlined) !important;
  color: var(--button-color-outlined) !important;
}
.bgColor:disabled {
  opacity: 0.7;
}
.title {
  font-size: 1.5rem !important;
}
.subTitle {
  font-size: 1rem;
  font-weight: 400;
  color: var(--subtitle-modal-text-color);
}
.container {
  margin-top: 2px;
}
.gridItem {
  padding-top: 0;
}
.input {
  display: flex !important;
  flex-direction: column !important;
  align-items: start !important;
  justify-content: end !important;
}
.inputSelect {
  width: 100% !important;
}
.label {
  font-size: 14px;
}
.span {
  font-size: 14px;
  font-weight: 400;
}
.button {
  margin-top: 10px !important;
  width: 150px;
  margin-bottom: 10px !important;
}
.menuIcon {
  visibility: visible !important;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
}
.buttonContainerEdit {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
}
.buttonContainerDelete {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}
.textIcon {
  font-weight: 400 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}
.boxDelete {
  padding: 20px 0;
}
.containerDelete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 20px;
}
.containerEdit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 20px;
}
.navItem {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: start;
  border: none;
  /* border: 1px solid #b7b7b7; */
  /* border-radius: 4px; */
  padding: 4px;
  transition: 0.2s ease-in;
}
.navItem:hover {
  background-color: #b7b7b7;
}
.navItem3 {
  display: flex;
  width: 100%;
  flex-direction: column !important;
  align-items: center;
  justify-content: start;
  border: none;
  padding: 4px;
  transition: 0.2s ease-in;
}
.navItem2 {
  display: flex;
  flex-direction: column !important;
  align-items: start;
  justify-content: start;
  border: none;
  /* border: 1px solid #b7b7b7;
  border-radius: 4px; */
  padding: 4px;
  transition: 0.2s ease-in;
}
.navItem2:hover {
  background-color: #b7b7b7;
}
.link {
  text-decoration: none;
  color: #333;
}
.subItems {
  display: flex;
  align-items: center;
}
.subItems2 {
  display: flex;
  align-items: center;
}
.ov:hover {
  color: white;
  text-decoration: none;
}
.subItems:hover {
  color: white !important;
  text-decoration: none;
}
@media only screen and (max-width: 600px) {
  /* Estilos para dispositivos pequeños aquí */
  .title {
    width: 100%;
    text-align: center;
  }
  .subTitle {
    width: 100%;
    text-justify: distribute;
  }
  .button {
    width: 100%;
  }
  .bgColor {
    width: 100%;
  }
  .bgColorDelete {
    width: 100%;
  }
  .bgColorOutlined {
    width: 100%;
  }
  .textIcon {
    gap: 0px;
    margin-bottom: 10px;
  }
  .hero {
    padding: 10px !important;
  }
  .container {
    margin-top: 0 !important;
    padding: 0 0 10px 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .icon {
    display: none;
  }
}
