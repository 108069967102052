.gridContainer{
    text-align: center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.gridTextContainer{
    text-align: start;
}
.optionsContainer{
    display:flex;
    flex-wrap:wrap;
    padding: 16px;
}
.navItem{
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    border: 1px solid #b7b7b7;
    border-radius: 4px;
    padding: 10px;
    transition: .2s ease-in;
}
.navItem:hover{
    background-color: #b7b7b7;
}
.link{
    text-decoration: none;
    color: #333;
}
@media only screen and (max-width: 600px) {
    .gridTextContainer{
        text-align: center;
        margin-bottom: 10px !important;
    }
}