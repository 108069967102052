.header{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 4px;
    margin-bottom: 5px;
    color: var(--title-color) !important;
}
.iconHeader{
    color: var(--iconHeader-color) !important;
}
.subtitle{
    font-size: 20px;
    color: var(--subtitle-color) !important;
    font-weight: 600;
    padding-left: 3px;
}
.cardBg{
    /* background-color: transparent !important; */
    background-color: var(--inst-li-blue-head-dash-other) !important;
    box-shadow: none !important;
}
.bgColor{
    background-color: var(--create-button-bg) !important;
    color: #fff !important;
}
.cardContent{
    display: flex !important;    
    align-items: start !important;
    gap:5px;
    color: #FFF !important;
    height: 190px;
    padding-top: 50px !important;
    
}
@media only screen and (max-width: 600px) { /* Estilos para dispositivos pequeños aquí */
    .header{
        text-align: center;
        font-size: 1.2rem !important;
        display: flex;
        justify-content: center;
    }   
    .cardContent{
        display: flex !important;
        text-align: center !important;
        flex-direction: column ;
    }
    .iconHeader{
        display: none;
    }
    .subtitle{
        text-align: justify;
    }
    .bgColor{
        width: 100% !important;
        display: block !important;
    }
}