.header{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 4px;
    margin-bottom: 5px;
}
.subtitle{
    font-size: 14px;
}
.bgColor:hover .iconButton{
    /* color: #FFF !important; */
    color: var(--inst-green-dark) !important;
}
.iconButton{
    font-size: 10px !important;
    margin-right: 5px !important;
    /* color: var(--border-green) !important; */
    color: #FFF !important;
    border-radius: 0 !important;
}
.bgColor{
    /* background-color: var(--transparent) !important; */
    background-color: var(--inst-green) !important;
    border: 1px solid var(--inst-green) !important;
    color: #FFF !important;
}
.bgColor:hover{
    background-color: var(--border-green) !important;
    color: #FFF !important;
}

.cardContent{
    display: flex !important;
    padding: 10px 0px !important;
    align-items: start !important;
    gap:5px;
}
@media only screen and (max-width: 600px) { /* Estilos para dispositivos pequeños aquí */
    .header{
        text-align: center;
        font-size: 1.2rem !important;
        display: flex;
        justify-content: center;
    }   
    .cardContent{
        display: flex !important;
        text-align: center !important;
        flex-direction: column ;
    }
    .iconHeader{
        display: none;
    }
    .subtitle{
        text-align: justify;
    }
    .bgColor{
        width: 100% !important;
        display: block !important;
    }
    .headerCont{
        display: flex;
        flex-direction: column;
    }
}