.cardContainter{
    margin-top: 10px !important;
    /* background-color: var(--inst-card-bg) !important;
    color: #FFF !important; */
    background-color: var(--inst-black-10) !important;
    color: #000 !important;
}
.cardContent{
    padding: 16px !important;
    margin: 10px !important;
}
.optionsContainer{
    padding: 16px !important;
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: center !important;
}