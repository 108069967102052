.bgColor{
  /* background-color: var(--transparent) !important; */
  background-color: var(--inst-green) !important;
  border: 1px solid var(--inst-green) !important;
  color: #FFF !important;
}
.bgColor:hover{
  background-color: var(--border-green) !important;
  color: #FFF !important;
}
.bgColorOrange{
  /* background-color: var(--transparent) !important; */
  /* border: 1px solid var(--border-blue) !important; */
  background-color: var(--border-blue) !important;
  color: #FFF !important;
}
.bgColorOrange:hover{
  background-color: var(--border-blue) !important;
  color: #FFF !important;
}
.bgColorBlue{
  /* background-color: var(--transparent) !important; */
  /* border: 1px solid var(--border-orange) !important; */
  background-color: var(--border-orange) !important;
  color: #FFF !important;
}
.bgColorBlue:hover{
  background-color: var(--border-orange) !important;
  color: #FFF !important;
}
.bgColorEdit{
  /* background-color: var(--create-button-bg) !important;
  color: var(--button-color) !important; */
  background-color: var(--inst-orange) !important;
  border: 1px solid var(--inst-orange) !important;
  color: #FFF !important;
}
.bgColorEdit:hover{
  background-color: var(--inst-orange) !important;
  color: #FFF !important;
}
.bgColorModal{
  background-color: var(--border-green) !important;
  color: #FFF !important;
}
.textField {
  background-color: #fff !important;
  border-radius: 5px !important;
  color: var(--inst-card-bg) !important;
  /* background-color: var(--inst-li-blue) !important;
  border-radius: 5px !important;
  border: 1px solid var(--inst-blue) !important;
  color: #FFF !important; */
}
.textField2 {
  /* background-color: var(--inst-card-bg) !important; */
  background-color: #fff !important;
  border-radius: 5px !important;
  border: 1px solid var(--inst-blue) !important;
  color: var(--inst-card-bg) !important;
}
.bgColor:hover .iconButton{
  /* color: #FFF !important; */
  color: var(--inst-green-dark) !important;
}
.relativeContainer{
  position: relative;
  top: -169px;
}
.dialogContainer{
  background-color: var(--inst-card-bg) !important;
  color: #FFF !important;
}
.iconButton{
  font-size: 10px !important;
  margin-right: 5px !important;
  /* color: var(--border-green) !important; */
  color: #FFF !important;
  border-radius: 0 !important;
}
.iconBg{
  color: var(--inst-card-bg);
}
.bgColorDelete{
  background-color: #800e0e !important;
  color: var(--button-color) !important;
}
.bgColorOutlined{
  /* border-color: var(--button-color-outlined) !important; */
  border-color: var(--button-color-outlined) !important;
  border: none !important;
  color: #2d3037 !important;
}
.bgColor:disabled{
  opacity: .7;
}
.iconTextHeader{
  display: flex;
  align-items: center;
  gap:5px;
}
.title{
  font-size: 1.5rem !important;
}
.subTitle{
  font-size: 1rem;
  font-weight: 400;
  color: var(--inst-card-bg);
}
.container{
  margin-top: 2px;
}
.gridItem{
  padding-top: 0;
}
.input{
  display: flex !important;
  flex-direction: column !important;
  align-items: start !important;
  justify-content: end !important;
}
.inputSelect{
  width: 100% !important;
}
.label{
  font-size: 14px;
}
.labelFilter{
  color: var(--inst-card-bg);
}
.span{
  font-size: 14px;
  font-weight: 400;
}
.button{
  margin-top: 10px !important;
  width: 150px;
  margin-bottom: 10px !important;
}
.menuIcon {
  visibility: visible !important;
}
.buttonContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
}
.buttonContainerEdit{
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
}
.buttonContainerDelete{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}
.textIcon{
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-size: 24px;
  gap: 5px;
}
.textIconDelete{
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  gap: 5px;
}
.textIconSpan{
  font-weight: 400 !important;
  font-size: 16px;
}
.boxDelete{
  padding: 20px 0;
  background-color: var(--delete-button-bg);
  color: #FFF !important;
}
.containerDelete{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 20px;
}
.containerEdit{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 20px;
}
.map {
  height: 480px !important;
  width: 100% !important;
  margin-bottom:5px !important;
}
.acordionDetails{
  /* background-color: var(--inst-black-10); */
  color: var(--inst-black-10);
  background-color: orange !important;
  border: none;
  border-bottom: none;
  /* margin-bottom: 5px; */
}
@media only screen and (max-width: 600px) { /* Estilos para dispositivos pequeños aquí */
  .title{
      width: 100%;
      text-align: center;
  }   
  .subTitle{
      width: 100%;
      text-justify:distribute;
  }
  .button{
      width: 100%;
  }
  .bgColor{
      width: 100%;
  }
  .bgColorEdit{
      width: 100%;
  }
  .bgColorModal{
      width: 100%;
  }
  .bgColorDelete{
      width: 100%;
  }
  .bgColorOutlined{
      width: 100%;
  }
  .textIcon{
      gap: 0px;
      margin-bottom: 10px;
  }
  .iconBg{
      display: none !important;
  }
  .relativeContainer{
      position: relative;
      top: 0px;
  }
}